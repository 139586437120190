import React from 'react'

import Button from "./button";

const MissionStatment = () => {
   return ( 
   <div className="px-10 py-10  w-full h-auto MSSection">
       <div className='h-full w-full lg:w-7/12'>

        <h1 className='font-bold mb-10 leading-tight h-auto'>
            Our mission is to
            help brands grow
            products that are
            better for people
            & our planet.
        </h1>
        <Button 
            padding=" px-2 lg:px-40"
            width={'w-full'}
            outlined
            margin=" my-4 md:mb-0"
            text='Our Mission And Values'
            to='/mission'
        />
       </div>

    </div>
   )
}

export default MissionStatment