import React from 'react'

import Button from './button'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const Slide2 = props => {
  const { background, title, description, button, image, split } = props
  return (
    <div className={`swiper-slide object-cover flex flex-col w-full relative card ${split ? '' : 'mb-8'} md:w-screen`}>
      <div className={` rounded flex object-cover flex-col items-center`}>
        {image ? (
          <img
            className=" w-full h-32 lg:h-48 rounded-t-3xl  object-cover"
            src={imageUrlFor(buildImageObj(image)).url()}
          />
        ) : null}
        <h2 className="w-full font-bold h-24 lg:h-16 relative p-4 w-full text-2xl">{title}</h2>
        {description ? (
          <p className=" w-full  lg:h-56  mb-8 px-4 pt-4">{description}</p>
        ) : null}
        {/* {button ? <Button text="Interested? Get in touch" to="/contact" full outlined className="mr-auto mb-20 ml-auto"/> : null} */}
      </div>
    </div>
  )
}

export default Slide2
