import { Link } from 'gatsby'
import React from 'react'
import { format } from 'date-fns'
import EllipsisText from 'react-ellipsis-text'

import BlockText from './block-text'

import { buildImageObj, cn, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

function BlogPostPreview(props) {

  return (
    <Link
      className="flex swiper-slide flex-col justify-start items-center overflow-y-hidden card max-w-md sm:w-full  rounded mr-2"
      to={getBlogUrl(props.publishedAt, props.slug.current)}
    >
      <div className="w-full h-32 lg:h-24 object-cover">
       
        {props.mainImage && props.mainImage.asset && (
          <div className="w-full overline h-20 object-cover"> 

            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .url()}
              alt={props.mainImage.alt}
              className="w-full h-32 lg:h-24 object-cover"
            />

          </div>

        )}
      </div>
      <div className="inline-block relative w-full overlined p-4 md:p-8">
        <strong
          className={`inline-block realtive w-full font-semibold h-16 mb-6 text-2xl ${
            props._rawExcerpt ? 'mb-4' : 'mb-0'
          }`}
        >
          {props.title}
        </strong>
        {props._rawExcerpt && (
          <div className="inline-block relative w-full h-56">
            
            <BlockText blocks={props._rawExcerpt} length={48}/>
          </div>
        )}

        {/* {props._rawExcerpt && (
          <div className="inline-block relative w-full mt-10 text-md">
            <p>{format(new Date(props.publishedAt), 'MMMM DD, YYYY')}</p>
          </div>
        )} */}
      </div>
    </Link>
  )
}

export default BlogPostPreview
